import React from "react";
import { FeatureItem } from "../index";

const features = [
  {
    title: "A birds-eye view of all your projects",
    description:
      "Drag and drop your economic development projects across fully customizable pipelines to help you intuitively visualize portfolio operations.",
    icon: (
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
      />
    ),
  },
  {
    title: "Make the connections",
    description:
      "You can easily associate a project with contacts, businesses, incentives or partner organizations to see who and what is involved in every project you pursue.",
    icon: (
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
      />
    ),
  },
  {
    title: "Easily add attachments",
    description: `Tired of not being able to find that document or other file? Easily upload attachments to a project so you and your team can quickly find them when you need them.`,
    icon: (
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
      />
    ),
  },
];

export default () => (
  <>
    <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
      <div className="relative">
        <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
          All your projects in one place.
        </h4>
        <p className="mt-3 text-lg leading-7 text-gray-500">
          See your entire pipeline and where each project is in the pipeline.
          Everyone works differently, so you can build your own custom pipelines
          for your organization's specific workflows.
        </p>
        <ul className="mt-10">
          {features.map((item) => {
            return (
              <FeatureItem
                icon={item.icon}
                title={item.title}
                description={item.description}
              />
            );
          })}
        </ul>
      </div>
      <video autoPlay loop muted playsInline>
        <source
          src="https://res.cloudinary.com/dfq1pflmk/video/upload/v1599006618/Growlab/Video_2_1_2.mp4"
          type="video/mp4"
        />
      </video>
    </div>
  </>
);
