import React from "react";
import {
  TrendingUpIcon,
  RefreshIcon,
  LocationMarkerIcon,
  LightningBoltIcon,
  EmojiHappyIcon,
  PresentationChartBarIcon,
  UserGroupIcon,
  TagIcon,
} from "@heroicons/react/outline";

const IconBlock = ({ children }) => (
  <div className="flex items-center justify-center h-12 w-12 rounded-md bg-gray-300 text-gray-800">
    {children}
  </div>
);

const ICON_STYLES = { height: 24, width: 24 };

const things = [
  {
    img: <TrendingUpIcon style={ICON_STYLES} />,
    title: "Made for economic development",
    description:
      "We’re not a sales CRM wrangled for use as an economic development tool. We’re built specifically for you.",
  },
  {
    img: <LightningBoltIcon style={ICON_STYLES} />,
    title: "Easy-to-use and intuitive",
    description:
      "Have existing data somewhere else like Microsoft Access? We can import your data easily.",
  },
  {
    img: <RefreshIcon style={ICON_STYLES} />,
    title: " Simple to integrate",
    description:
      "A simple to use interface that doesn’t require hours of training.",
  },
  {
    img: <EmojiHappyIcon style={ICON_STYLES} />,
    title: "Web and mobile ready",
    description:
      "Our app works just as nicely on your phone and tablet as it does on your desktop.",
  },
  {
    img: <LocationMarkerIcon style={ICON_STYLES} />,
    title: "Business Map",
    description: "Visualize businesses using the map view with filtering.",
  },
  {
    img: <PresentationChartBarIcon style={ICON_STYLES} />,
    title: "Real-time Data",
    description: "Watch survey results and other data come in real-time.",
  },
  {
    img: <UserGroupIcon style={ICON_STYLES} />,
    title: "User Accounts",
    description:
      "Give your colleages, city staff and businesses one place to connect.",
  },
  {
    img: <TagIcon style={ICON_STYLES} />,
    title: "Tags",
    description:
      "Organize businesses, surveys and cases based on industry, location, size, diversity measures & more.",
  },
];

export default () => (
  <div className="bg-gray-50 overflow-hidden">
    <div className="relative max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
      <svg
        className="absolute top-0 left-full transform -translate-x-1/2 -translate-y-3/4 lg:left-auto lg:right-full lg:translate-x-2/3 lg:translate-y-1/4"
        width="404"
        height="784"
        fill="none"
        viewBox="0 0 404 784"
      >
        <defs>
          <pattern
            id="8b1b5f72-e944-4457-af67-0c6d15a99f38"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <rect
              x="0"
              y="0"
              width="4"
              height="4"
              className="text-gray-200"
              fill="currentColor"
            />
          </pattern>
        </defs>
        <rect
          width="404"
          height="784"
          fill="url(#8b1b5f72-e944-4457-af67-0c6d15a99f38)"
        />
      </svg>

      <div className="relative lg:grid lg:grid-cols-3 lg:col-gap-8">
        <div className="lg:col-span-1">
          <h3 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            Why we're better
          </h3>
        </div>
        <div className="mt-10 sm:grid sm:grid-cols-2 sm:col-gap-8 sm:row-gap-10 lg:col-span-2 lg:mt-0">
          {things.map((item) => {
            return (
              <div key={item.title}>
                <IconBlock>{item.img}</IconBlock>
                <div className="mt-5">
                  <h4 className="text-lg leading-6 font-medium text-gray-900">
                    {item.title}
                  </h4>
                  <p className="mt-2 text-base leading-6 text-gray-500">
                    {item.description}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  </div>
);
