import React from "react";

/* This example requires Tailwind CSS v2.0+ */
export default function Example() {
  return (
    <div className="bg-gray-900">
      <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
          <span className="block">Boost your productivity.</span>
          <span className="block">Start using Growlab today.</span>
        </h2>
        <p className="mt-4 text-lg leading-6 text-gray-400">
          Stop managing economic development the hard way.
        </p>
        <a
          href="https://calendly.com/Growlab-crm/Growlab-demo"
          className="mt-8 w-full inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-green-600 bg-white hover:bg-green-50 sm:w-auto"
        >
          Book A Demo
        </a>
      </div>
    </div>
  );
}
