/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import styled from "styled-components";

const TagContainer = styled.div`
  height: 24px;
  width: 100px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-left: 16px;
  font-size: 12px;
  font-weight: 400;
  border-radius: 25px;
`;

const includedFeatures = [
  "Surveys",
  "Case Management",
  "Invite your team",
  "Todos",
  "Contacts database",
  "Email Integration",
  "Project Management",
  "Reports & Analytics",
  "Training & Support",
  "No Hidden Fees",
];

const monthlyPricing = [
  {
    name: "Starter",
    href: "https://calendly.com/Growlab-crm/Growlab-demo",
    btnText: "Try Starter",
    price: "$350",
    addonAfter: "/mo",
    description: "1 to 5 Users",
    includedFeatures: ["1 to 5 Users", ...includedFeatures],
  },
  {
    name: "Startup",
    href: "https://calendly.com/Growlab-crm/Growlab-demo",
    btnText: "Try Startup",
    mostPopular: true,
    price: "$450",
    addonAfter: "/mo",
    description: "5-10 Users",
    includedFeatures: ["5-10 Users", ...includedFeatures],
  },
  {
    name: "Growth",
    href: "https://calendly.com/Growlab-crm/Growlab-demo",
    btnText: "Try Growth",
    price: "$550",
    addonAfter: "/mo",
    description: "10-20 Users",
    includedFeatures: ["10-20 Users", ...includedFeatures],
  },
  {
    name: "Scale",
    href: "https://calendly.com/Growlab-crm/Growlab-demo",
    btnText: "Setup a phone call",
    btnColor: "gray",
    price: "Contact Us",
    addonAfter: "",
    description: "20+ users",
    includedFeatures: ["20+ users", ...includedFeatures],
  },
];

const discount = 0.9;

const annualPricing = [
  {
    price: `$${parseInt(
      monthlyPricing?.[0]?.price?.replace("$", "") * discount
    )}`,
    addonAfter: "/mo",
  },
  {
    price: `$${parseInt(
      monthlyPricing?.[1]?.price?.replace("$", "") * discount
    )}`,
    addonAfter: "/mo",
  },
  {
    price: `$${parseInt(
      monthlyPricing?.[2]?.price?.replace("$", "") * discount
    )}`,
    addonAfter: "/mo",
  },
  {
    price: "Contact Us",
    addonAfter: "",
  },
];

const highlighted =
  "relative w-1/2 bg-white border-gray-200 rounded-md shadow-sm py-2 text-sm font-medium text-gray-900 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:z-10 sm:w-auto sm:px-8";

const unhighlighted =
  "ml-0.5 relative w-1/2 border border-transparent rounded-md py-2 text-sm font-medium text-gray-700 whitespace-nowrap focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:z-10 sm:w-auto sm:px-8";

export default function Example() {
  const [monthly, setMonthly] = useState(true);

  const pricing = monthly
    ? monthlyPricing
    : monthlyPricing.map((item, index) => {
        return {
          ...item,
          price: annualPricing[index].price,
          addonAfter: annualPricing[index].addonAfter,
        };
      });
  return (
    <div className="bg-white" id="pricing">
      <div className="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:flex-col sm:align-center">
          <h1 className="text-5xl font-extrabold text-gray-900 sm:text-center">
            Simple Pricing
          </h1>
          <p className="mt-5 text-xl text-gray-500 sm:text-center">
            Let's make economic development better, together.
          </p>
          <div className="relative self-center mt-6 bg-gray-100 rounded-lg p-0.5 flex sm:mt-8">
            <button
              type="button"
              onClick={() => setMonthly(true)}
              className={monthly ? highlighted : unhighlighted}
            >
              Monthly billing
            </button>
            <button
              type="button"
              onClick={() => setMonthly(false)}
              className={!monthly ? highlighted : unhighlighted}
            >
              Yearly billing (10% discount)
            </button>
          </div>
        </div>
        <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-4xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-4">
          {pricing.map((tier) => (
            <div
              key={tier.name}
              className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200"
            >
              <div className="p-6">
                <h2 className="text-lg leading-6 font-medium text-gray-900">
                  {tier.name}{" "}
                  {tier.mostPopular && (
                    <TagContainer className="bg-gray-800">
                      Most Popular
                    </TagContainer>
                  )}
                </h2>
                <p className="mt-4 text-sm text-gray-500">{tier.description}</p>
                <p className="mt-8">
                  <span className="text-4xl font-extrabold text-gray-900">
                    {tier.price}
                  </span>
                  {tier.addonAfter && (
                    <span className="text-base font-medium text-gray-500">
                      {tier.addonAfter}
                    </span>
                  )}
                </p>
                <a
                  href={tier.href}
                  className={`mt-8 block w-full bg-${
                    tier.btnColor || "green"
                  }-800 border border-${
                    tier.btnColor || "green"
                  }-800 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-${
                    tier.btnColor || "green"
                  }-900`}
                >
                  {tier.btnText}
                </a>
              </div>
              <div className="pt-6 pb-8 px-6">
                <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">
                  EVERYTHING INCLUDED:
                </h3>
                <ul className="mt-6 space-y-4">
                  {tier.includedFeatures.map((feature) => (
                    <li key={feature} className="flex space-x-3">
                      <CheckIcon
                        className="flex-shrink-0 h-5 w-5 text-green-500"
                        aria-hidden="true"
                      />
                      <span className="text-sm text-gray-500">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
