import React from "react";
import styled from "styled-components";
import logo from "../../lib/media/logo.svg";
import AnchorLink from "react-anchor-link-smooth-scroll";

const Button = styled.a`
  /* background: ${(p) => p.theme.colors.primary1}; */
`;

const SecondaryButton = styled(AnchorLink)`
  /* background: ${(p) => p.theme.colors.primary10};
  color: ${(p) => p.theme.colors.primary1}; */
`;

// const MobileMenu = ({onClose}) => {
//   return (
//     <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
//       <div className="rounded-lg shadow-md">
//         <div
//           className="rounded-lg bg-white shadow-xs overflow-hidden"
//           role="menu"
//           aria-orientation="vertical"
//           aria-labelledby="main-menu"
//         >
//           <div className="px-5 pt-4 flex items-center justify-between">
//             <div>
//               <img className="h-8 w-auto" src={logo} alt="" />
//             </div>
//             <div className="-mr-2">
//               <button
//                 type="button"
//                 className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
//                 aria-label="Close menu"
//                 onClick={() => onClose()}
//               >
//                 <svg
//                   className="h-6 w-6"
//                   stroke="currentColor"
//                   fill="none"
//                   viewBox="0 0 24 24"
//                 >
//                   <path
//                     strokeLinecap="round"
//                     strokeLinejoin="round"
//                     strokeWidth="2"
//                     d="M6 18L18 6M6 6l12 12"
//                   />
//                 </svg>
//               </button>
//             </div>
//           </div>
//           <div className="px-2 pt-2 pb-3">
//             <a
//               href="#"
//               className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
//               role="menuitem"
//             >
//               Product
//             </a>
//             <a
//               href="#"
//               className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
//               role="menuitem"
//             >
//               Features
//             </a>
//             <a
//               href="#"
//               className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
//               role="menuitem"
//             >
//               Marketplace
//             </a>
//             <a
//               href="#"
//               className="mt-1 block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out"
//               role="menuitem"
//             >
//               Company
//             </a>
//           </div>
//           <div>
//             <a
//               href="#"
//               className="block w-full px-5 py-3 text-center font-medium text-green-600 bg-gray-50 hover:bg-gray-100 hover:text-green-700 focus:outline-none focus:bg-gray-100 focus:text-green-700 transition duration-150 ease-in-out"
//               role="menuitem"
//             >
//               Log in
//             </a>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

const navigation = [
  // { name: " " },
  // { name: "Features", href: "#features" },
  // { name: "Pricing", href: "#" },
  // { name: "Book a Demo", href: "#features" },
  // { name: "Log in", href: "#" },

  // <AnchorLink
  //   key="Features"
  //   href="#features"
  //   className="text-sm font-semibold leading-6 text-gray-900"
  // >
  //   Features
  // </AnchorLink>,
  // <AnchorLink
  //   key="Features"
  //   href="#features"
  //   className="text-sm font-semibold leading-6 text-gray-900"
  // >
  //   Features
  // </AnchorLink>,
  <div className="text-sm font-semibold leading-6 text-gray-900" />,
  <AnchorLink
    key="Features"
    href="#features"
    className="text-sm font-semibold leading-6 text-gray-900"
  >
    Features
  </AnchorLink>,
  <AnchorLink
    key="Pricing"
    href="#pricing"
    className="text-sm font-semibold leading-6 text-gray-900"
  >
    Pricing
  </AnchorLink>,

  <a
    href="https://calendly.com/Growlab-crm/Growlab-demo"
    target="_blank"
    rel="noopener noreferrer"
    className="text-sm font-semibold leading-6 text-gray-900"
  >
    Book a Demo
  </a>,
  <a
    key="login"
    href="https://app.growlabcrm.com/login"
    target="_blank"
    rel="noopener noreferrer"
    style={{ width: 80, display: "inline-flex", padding: "2px 14px" }}
    // className="text-sm font-semibold leading-6 text-green-800"
    className="flex text-sm bg-green-100 items-center justify-center border border-transparent leading-6 font-semibold rounded-md text-green-900  hover:text-green-600 hover:bg-green-50 focus:outline-none focus:shadow-outline-green focus:border-green-300 transition duration-150 ease-in-out"
  >
    Login
  </a>,
];

export default () => {
  return (
    <div className="relative bg-white overflow-hidden">
      <div className="max-w-screen-xl mx-auto ">
        <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
          <svg
            className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>

          <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
            <nav className="relative flex items-center justify-between sm:h-10 lg:justify-start">
              <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                <div className="flex items-center justify-between w-full md:w-auto">
                  <img
                    className="h-10 m-auto w-auto md:h-8"
                    src={logo}
                    alt="Logo"
                  />
                  <div className="hidden lg:ml-12 lg:block lg:space-x-14">
                    {navigation.map((item) => item)}
                  </div>
                  {/* <div className="-mr-2 flex items-center md:hidden">
                    <button
                      type="button"
                      className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out"
                      id="main-menu"
                      aria-label="Main menu"
                      onClick={() => setShowMobileMenu(!showMobileMenu)}
                      aria-haspopup="true"
                    >
                      <svg
                        className="h-6 w-6"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M4 6h16M4 12h16M4 18h16"
                        />
                      </svg>
                    </button>
                  </div> */}
                </div>
              </div>
              <div className="hidden md:block md:ml-10 md:pr-4">
                {/* <a
                  href="#"
                  className="font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out"
                >
                  Product
                </a>
                <a
                  href="#"
                  className="ml-8 font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out"
                >
                  Features
                </a>
                <a
                  href="#"
                  className="ml-8 font-medium text-gray-500 hover:text-gray-900 transition duration-150 ease-in-out"
                >
                  About Us
                </a>
                <a
                  href="#"
                  className="ml-8 font-medium text-green-600 hover:text-green-900 transition duration-150 ease-in-out"
                >
                  Log in
                </a> */}
              </div>
            </nav>
          </div>
          {/* {showMobileMenu && (
            <MobileMenu onClose={() => setShowMobileMenu(!showMobileMenu)} />
          )} */}
          <main className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-4 xl:mt-28">
            <div className="sm:text-center lg:text-left">
              <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
                {/* Close more projects
                <br />
                <span className="text-green-600"> than ever before</span> */}
                Retention & Expansion
                <br />
                <span className="text-green-600">in one place.</span>
              </h2>
              <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                {/* Growlab is a CRM made for economic development. Accelerate your
                business retention & expansion process with easy-to-use project
                pipelines, campaigns, collaboration, and analytics. */}
                Growlab is a Business Retention and Expansion CRM tool built
                specifically for economic development professionals. Accelerate
                your program with survey tools, project pipelines, reporting
                features, campaigns, and collaboration.
              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                <div className="rounded-md shadow">
                  <Button
                    href="https://calendly.com/Growlab-crm/Growlab-demo"
                    target="_blank"
                    className="w-full flex bg-green-900 items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white hover:bg-green-500 focus:outline-none focus:border-green-700 focus:shadow-outline-green transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                  >
                    Book a Demo
                  </Button>
                </div>
                <div className="mt-3 sm:mt-0 sm:ml-3">
                  <SecondaryButton
                    href="#features"
                    className="w-full flex bg-green-100 items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-green-900  hover:text-green-600 hover:bg-green-50 focus:outline-none focus:shadow-outline-green focus:border-green-300 transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                  >
                    Learn More
                  </SecondaryButton>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
        <img
          className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
          src={`${process.env.PUBLIC_URL}/hero.jpeg`}
          //src="https://images.unsplash.com/photo-1533574383808-85e509c6f866?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80"
          alt=""
        />
      </div>
    </div>
  );
};
