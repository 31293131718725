import React, { useEffect } from "react";
import Features from "./components/Features";
import Features3 from "./components/Features3";
import Hero from "./components/Hero";
import Footer2 from "./components/Footer";
import Pricing from "./components/Pricing";
import BlogPost from "./components/BlogPost";
import BlogsList from "./components/BlogsList";
import Cta2 from "./components/Cta2";
import RecentPublications from "./components/RecentPublications";
import theme from "./lib/theme";
import { ThemeProvider } from "styled-components";
import { HelmetProvider } from 'react-helmet-async';

const HomePage = () => (
  <>
    <Hero />
    <Features />
    {/* <Features2 /> */}
    <Features3 />
    <Pricing />
    <Cta2 />
    <RecentPublications />
    <Footer2 />
  </>
);

const BlogPostPage = () => {
  return (
    <>
      <BlogPost slug={window?.location?.pathname?.replace("/blog/", "")} />
      <Footer2 />
    </>
  );
};

function App() {
  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      window.mixpanel.track("Page Loaded");
    }
  }, []);

  const isBlogPost = window.location.pathname?.includes("/blog/");
  const isBlogs = window.location.pathname === "/blogs";

  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        {isBlogPost && <BlogPostPage />}
        {isBlogs && <BlogsList />}
        {!isBlogPost && !isBlogs && <HomePage />}
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
