import React, { useState, useEffect } from "react";
import { GraphQLClient } from "graphql-request";
import { gql } from "graphql-request";
import day from "dayjs";

const graphcms = new GraphQLClient(
  "https://api-us-east-1.graphcms.com/v2/ckymbhj6s0dv101yy11g0bi0t/master"
);

const QUERY = gql`
  {
    posts(last: 3) {
      id
      title
      slug
      excerpt
      tags
      author {
        name
        picture {
          url
        }
      }
      createdAt
      content {
        html
      }
    }
  }
`;

/* This example requires Tailwind CSS v2.0+ */
const stuff = [
  {
    title: "Boost your conversion rate",
    href: "#",
    category: {
      name: "Article",
      href: "#",
      color: "bg-indigo-100 text-indigo-800",
    },
    description:
      "Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat massa dictumst amet. Sapien tortor lacus arcu.",
    date: "Mar 16, 2020",
    datetime: "2020-03-16",
    author: {
      name: "Paul York",
      href: "#",
      imageUrl:
        "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    readingTime: "6 min",
  },
  {
    title: "How to use search engine optimization to drive sales",
    href: "#",
    category: { name: "Video", href: "#", color: "bg-pink-100 text-pink-800" },
    description:
      "Nullam risus blandit ac aliquam justo ipsum. Quam mauris volutpat massa dictumst amet. Sapien tortor lacus arcu.",
    date: "Mar 10, 2020",
    datetime: "2020-03-10",
    author: {
      name: "Dessie Ryan",
      href: "#",
      imageUrl:
        "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    readingTime: "4 min",
  },
  {
    title: "Improve your customer experience",
    href: "#",
    category: {
      name: "Case Study",
      href: "#",
      color: "bg-green-100 text-green-800",
    },
    description:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ab iure iusto fugiat commodi sequi.",
    date: "Feb 12, 2020",
    datetime: "2020-02-12",
    author: {
      name: "Easer Collins",
      href: "#",
      imageUrl:
        "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
    readingTime: "11 min",
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function RecentPublications() {
  const [posts, setPosts] = useState([]);

  async function getPosts() {
    const { posts } = await graphcms.request(QUERY);
    posts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    setPosts(posts);
  }

  useEffect(() => {
    getPosts();
  }, []);

  console.log({
    posts,
  });

  return (
    <div className="bg-white pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
      <div className="relative max-w-lg mx-auto divide-y-2 divide-gray-200 lg:max-w-7xl">
        <div>
          <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
            Recent publications
          </h2>
          <p className="mt-3 text-xl text-gray-500 sm:mt-4">
            Explore the Latest Insights from the Growlab Team
          </p>
        </div>
        <div className="mt-8 grid gap-16 pt-8 lg:grid-cols-3 lg:gap-x-5 lg:gap-y-12">
          {posts?.map((post) => (
            <div key={post.id}>
              <div>
                {/* <span
                  className={classNames(
                    post.category.color,
                    "inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium"
                  )}
                >
                  {post.category.name}
                </span> */}
              </div>
              <a
                href={`${window.location.origin}/blog/${post.slug}`}
                className="block mt-4"
              >
                <p className="text-xl font-semibold text-gray-900">
                  {post.title}
                </p>
                <p className="mt-3 text-base text-gray-500">{post.excerpt}</p>
              </a>
              <div className="mt-6 flex items-center">
                <div className="flex-shrink-0">
                  <span className="sr-only">{post.author.name}</span>
                  <img
                    className="h-10 w-10 rounded-full"
                    src={post?.author?.picture?.url}
                    alt=""
                  />
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-gray-900">
                    <span>{post.author.name}</span>
                  </p>
                  <div className="flex space-x-1 text-sm text-gray-500">
                    <time dateTime={post.createdAt}>
                      {day(post.createdAt)?.format("MMM DD, YYYY")}
                    </time>
                    <span aria-hidden="true">&middot;</span>
                    {/* <span>{post.readingTime} read</span> */}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
