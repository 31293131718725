import logo from "../../lib/media/logo.svg";
import React, { useState, useEffect } from "react";
import { GraphQLClient } from "graphql-request";
import { gql } from "graphql-request";
import day from "dayjs";

const graphcms = new GraphQLClient(
  "https://api-us-east-1.graphcms.com/v2/ckymbhj6s0dv101yy11g0bi0t/master"
);

const QUERY = gql`
  {
    posts() {
      id
      title
      slug
      excerpt
      tags
      coverImage {
        url
      }
      author {
        name
        picture {
          url
        }
      }
      createdAt
      content {
        html
      }
    }
  }
`;

export default function BlogsList() {
  const [posts, setPosts] = useState([]);

  async function getPosts() {
    const { posts } = await graphcms.request(QUERY);
    posts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    setPosts(posts);
  }

  useEffect(() => {
    getPosts();
  }, []);

  console.log({
    posts
  });
  
  return (
    <>
      <div
        style={{ display: "flex", justifyContent: "center", paddingTop: 32 }}
      >
        <a href={`${window.location.origin}`}>
          <img className="h-10 w-auto center" src={logo} alt="" />
        </a>
      </div>
      <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:max-w-4xl">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Recent publications</h2>
          <div className="mt-16 space-y-20 lg:mt-20 lg:space-y-20">
          {posts.map((post) => (
            <article key={post.id} className="relative isolate flex flex-col gap-8 lg:flex-row">
              <div className="relative aspect-[16/9] sm:aspect-[2/1] lg:aspect-square lg:w-64 lg:shrink-0">
                <img
                  src={post.coverImage.url}
                  alt=""
                  className="absolute inset-0 h-full w-full rounded-2xl bg-gray-50 object-cover"
                />
                <div className="absolute inset-0 rounded-2xl ring-1 ring-inset ring-gray-900/10" />
              </div>
              <div>
                <div className="flex items-center gap-x-4 text-xs">
                  <time dateTime={post.createdAt} className="text-gray-500">
                    {day(post.createdAt)?.format("MMM DD, YYYY")}
                  </time>
                </div>
                <div className="group relative max-w-xl">
                  <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                    <a href={`${window.location.origin}/blog/${post.slug}`}> 
                      <span className="absolute inset-0" />
                      {post.title}
                    </a>
                  </h3>
                  <p className="mt-5 text-sm leading-6 text-gray-600">{post.description}</p>
                </div>
                <div className="mt-6 flex border-t border-gray-900/5 pt-6">
                  <div className="relative flex items-center gap-x-4">
                    <img src={post.author.picture.url} alt="" className="h-10 w-10 rounded-full bg-gray-50" />
                    <div className="text-sm leading-6">
                      <p className="font-semibold text-gray-900">
                        <a href={post.author.href}>
                          <span className="absolute inset-0" />
                          {post.author.name}
                        </a>
                      </p>
                      <p className="text-gray-600">{post.author.role}</p>
                    </div>
                  </div>
                </div>
              </div>
            </article>
          ))}
          </div>
        </div>
      </div>
    </div>
    </>
  )
}
