import React from "react";
import { FeatureItem } from "../index";
import surveyVid from "./survey.mp4";

const features = [
  {
    title: "Customizable survey templates",
    description:
      "Drag and drop to build your own surveys for site visits, annual reports, case follow up and more.",
    icon: (
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"
      />
    ),
  },
  {
    title: "Instant reporting",
    description:
      "Your survey results are immediately tallied and viewable in live reports.",
    icon: (
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
      />
    ),
  },
  {
    title: "Example surveys",
    description: `Choose from our library of expert-vetted BR&E surveys. Get your program jump started on day one.`,
    icon: (
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"
      />
    ),
  },
];

export default () => (
  <>
    <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
      <div className="relative">
        <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
          Powerful surveys, simplified
        </h4>
        <p className="mt-3 text-lg leading-7 text-gray-500">
          Business Retention and Expansion programs rely on surveys to get an
          accurate picture of businesses in their communities and regions, our
          survey software empowers you to capture this data and save it for year
          over year snapshots of growth and reporting.
        </p>
        <ul className="mt-10">
          {features.map((item) => {
            return (
              <FeatureItem
                icon={item.icon}
                title={item.title}
                description={item.description}
              />
            );
          })}
        </ul>
      </div>
      <video autoPlay loop muted playsInline style={{ borderRadius: 5 }}>
        <source src={surveyVid} type="video/mp4" />
      </video>
      {/* <img
        src={featurePNG}
        alt="survey-feature"
        style={{ maxHeight: 600, position: 'relative', top: -16, right: -32 }}
      /> */}
    </div>
  </>
);
