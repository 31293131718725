import logo from "../../lib/media/logo.svg";
import React, { useState, useCallback, useEffect } from "react";
import { GraphQLClient } from "graphql-request";
import { gql } from "graphql-request";
import styled from "styled-components";
import ReactSEO from "../ReactSEO";

const graphcms = new GraphQLClient(
  "https://api-us-east-1.graphcms.com/v2/ckymbhj6s0dv101yy11g0bi0t/master"
);

const QUERY = gql`
  query post($where: PostWhereUniqueInput!) {
    post(where: $where) {
      id
      title
      slug
      excerpt
      tags
      coverImage {
        url
      }
      author {
        name
        picture {
          url
        }
      }
      createdAt
      content {
        html
      }
    }
  }
`;

const BlogContainer = styled.div`
  h3 {
    color: #111827;
    font-weight: 600;
    font-size: 1.25em;
    margin-top: 1.6em;
    margin-bottom: 0.6em;
    line-height: 1.6;
  }
`;

export default function Example({ slug }) {
  const [post, setPost] = useState([]);

  const getPostCallback = useCallback(
    async function getPost() {
      try {
        if (slug) {
          const result = await graphcms.request(QUERY, {
            where: { slug },
          });
          console.log(result);
          setPost(result.post);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [slug]
  );

  useEffect(() => {
    getPostCallback();
  }, [getPostCallback]);

  function createMarkup() {
    return {
      __html: post?.content?.html?.replaceAll(
        "<p>",
        `<p class="mt-6 prose prose-green prose-lg text-gray-500 mx-auto">`
      ),
      //?.replaceAll("<h3>", `<h3 class="prose">`),
    };
  }

  return (
    <>
      <ReactSEO
        title={post?.title}
        name={post?.slug}
        author={post?.author?.name}
        description={post?.excerpt}
        image={post?.coverImage?.url}
        url={window.location.href}
      />
      <div
        style={{ display: "flex", justifyContent: "center", paddingTop: 32 }}
      >
        <a href={`${window.location.origin}`}>
          <img className="h-10 w-auto center" src={logo} alt="" />
        </a>
      </div>
      <div className="relative py-16 bg-white overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div
            className="relative h-full text-lg max-w-prose mx-auto"
            aria-hidden="true"
          >
            <svg
              className="absolute top-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
              />
            </svg>
            <svg
              className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
              />
            </svg>
            <svg
              className="absolute bottom-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
              />
            </svg>
          </div>
        </div>

        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            {post?.coverImage?.url && (
              <figure>
                <img
                  className="w-full rounded-lg"
                  src={post?.coverImage?.url}
                  alt=""
                  width={1310}
                  height={873}
                  style={{ marginBottom: 40 }}
                />
                {/* <figcaption>
            Sagittis scelerisque nulla cursus in enim consectetur quam.
          </figcaption> */}
              </figure>
            )}
            <h1>
              <span className="block text-base text-center text-green-600 font-semibold tracking-wide uppercase">
                From the Growlab Blog
              </span>
              <span className="mt-6 mb-6 block text-3xl text-center leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                {post?.title}
              </span>
            </h1>
            <BlogContainer>
              <div dangerouslySetInnerHTML={createMarkup()} />
            </BlogContainer>
          </div>
          {/* <div className="mt-6 prose prose-indigo prose-lg text-gray-500 mx-auto">
            <p>
              Faucibus commodo massa rhoncus, volutpat.{" "}
              <strong>Dignissim</strong> sed <strong>eget risus enim</strong>.
              Mattis mauris semper sed amet vitae sed turpis id. Id dolor
              praesent donec est. Odio penatibus risus viverra tellus varius sit
              neque erat velit. Faucibus commodo massa rhoncus, volutpat.
              Dignissim sed eget risus enim.{" "}
              <a href="#">Mattis mauris semper</a> sed amet vitae sed turpis id.
            </p>
          </div> */}
        </div>
      </div>
    </>
  );
}
