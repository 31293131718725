import React from "react";
import ProjectsFeature from "./ProjectsFeature";
import SurveyFeature from "./SurveyFeature";

const Dots = () => (
  <svg
    className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
    width="404"
    height="784"
    fill="none"
    viewBox="0 0 404 784"
  >
    <defs>
      <pattern
        id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
        x="0"
        y="0"
        width="20"
        height="20"
        patternUnits="userSpaceOnUse"
      >
        <rect
          x="0"
          y="0"
          width="4"
          height="4"
          className="text-gray-200"
          fill="currentColor"
        />
      </pattern>
    </defs>
    <rect
      width="404"
      height="784"
      fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
    />
  </svg>
);

const Dots2 = () => (
  <svg
    className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
    width="404"
    height="784"
    fill="none"
    viewBox="0 0 404 784"
  >
    <defs>
      <pattern
        id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
        x="0"
        y="0"
        width="20"
        height="20"
        patternUnits="userSpaceOnUse"
      >
        <rect
          x="0"
          y="0"
          width="4"
          height="4"
          className="text-gray-200"
          fill="currentColor"
        />
      </pattern>
    </defs>
    <rect
      width="404"
      height="684"
      fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
    />
  </svg>
);

/**
 * HeaderBlock
 *
 */

const HeaderBlock = () => (
  <div className="relative" id="features">
    <h3 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
      Built for Economic Development
    </h3>
    <p className="mt-4 max-w-3xl mx-auto text-center text-xl leading-7 text-gray-500">
      Is your organization in charge of recruiting and retaining businesses for
      your area? Growlab was built specifically to help you manage business
      growth at the local, regional, and state level.
    </p>
  </div>
);

/**
 * FeatureImg
 *
 */

/**
 * Feature1List
 *
 */
// const Feature1List = () => (
//   <>
//     <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
//       All your projects in one place.
//     </h4>
//     <p className="mt-3 text-lg leading-7 text-gray-500">
//       See your entire pipeline and where each project is in the pipeline.
//       Everyone works differently, so you can build your own custom pipelines for
//       your organization's specific workflows.
//     </p>
//     <ul className="mt-10">
//       <FeatureItem
//         icon={
//           <path
//             strokeLinecap="round"
//             strokeLinejoin="round"
//             strokeWidth="2"
//             d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
//           />
//         }
//         title="A birds-eye view of all your projects"
//         description="Drag and drop your economic development projects across fully customizable pipelines to help you intuitively visualize portfolio operations."
//       />
//       <FeatureItem
//         icon={
//           <path
//             strokeLinecap="round"
//             strokeLinejoin="round"
//             strokeWidth="2"
//             d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
//           />
//         }
//         title="Make the connections"
//         description="You can easily associate a project with contacts, businesses, incentives or partner organizations to see who and what is involved in every project you pursue."
//       />
//       <FeatureItem
//         icon={
//           <path
//             strokeLinecap="round"
//             strokeLinejoin="round"
//             strokeWidth="2"
//             d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
//           />
//         }
//         title="Easily add attachments"
//         description="Tired of not being able to find that document or other file? Easily upload attachments to a project so you and your team can quickly find them when you need them."
//       />
//     </ul>
//   </>
// );

export const FeatureItem = ({ icon, title, description }) => (
  <li className="mt-10">
    <div className="flex">
      <div className="flex-shrink-0">
        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-green-500 text-white">
          <svg
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            {icon}
          </svg>
        </div>
      </div>
      <div className="ml-4">
        <h5 className="text-lg leading-6 font-medium text-gray-900">{title}</h5>
        <p className="mt-2 text-base leading-6 text-gray-500">{description}</p>
      </div>
    </div>
  </li>
);

export default () => {
  return (
    <div className="py-16 bg-gray-50 overflow-hidden lg:py-24">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
        <>
          <Dots />
          <HeaderBlock />
          {/* FEATURE 1 */}
          {/* */}
          <SurveyFeature />
        </>
        <Dots2 />
        <div className="relative mt-12 sm:mt-16 lg:mt-24">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="lg:col-start-2">
              <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
                Always in the loop with todos
              </h4>
              <p className="mt-3 text-lg leading-7 text-gray-500">
                Keep your whole team on the same page throughout the process.
                Todos give you everything you need to organize, track, and close
                your next big economic development project.
              </p>

              <ul className="mt-10">
                <FeatureItem
                  icon={
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                    />
                  }
                  title="Assign todos"
                  description="Make sure everyone on your team knows what they're responsible for and when it's due. Assign by team members then quickly filter to get an idea of their workload and upcoming todos."
                />
                <FeatureItem
                  icon={
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                    />
                  }
                  title="Tie it all together"
                  description="Easily associate a todo to a given project, contact, business or other entity in your CRM. Then easily view all the todos related to a specific project."
                />
                <FeatureItem
                  icon={
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                    />
                  }
                  title="Reminder emails"
                  description="Never miss a deadline by setting reminder emails for when your next tasks are coming due."
                />
              </ul>
            </div>
            <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
              <video autoPlay loop muted playsInline>
                <source
                  src="https://res.cloudinary.com/dfq1pflmk/video/upload/v1599006798/Growlab/Video_1_1.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        </div>
        {/* FEATURE 3 */}
        <ProjectsFeature />
        {/* <ReportingFeature /> */}
      </div>
    </div>
  );
};
//
